import React, { useEffect } from "react";
import companyCardStyles from "./CompanyCard.module.css";
import clsx from "clsx";
import { ReviewStars } from "../ReviewStars";
import { Link } from "react-router-dom";

export const CompanyCard = ({ company, className }) => {
    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    return (
        <div className={clsx(companyCardStyles['company-card'], className)}>
            <Link to={`/company/${company.slug}`}>
                <h3>{company.name}</h3>
            </Link>
            <div className={companyCardStyles['review-stats']}>
                <div className={companyCardStyles['stars']}>
                    <ReviewStars rating={company.avg_rating} starWidth={15.88} />
                </div>
                <div className={companyCardStyles['values']}>
                    <span>{company.avg_rating}</span>
                    <span>|</span>
                    <span>{company.rating_count} Reviews</span>
                </div>

            </div>
            <div className={companyCardStyles['company-location']}>
                {company.address || '-'}
            </div>
            <div className={companyCardStyles['company-about']}>
                {company.description}
            </div>

        </div>
    );
};
