import React from "react";
import clsx from "clsx";
import { SearchComponent } from "../SearchComponent";
import homeBannerStyles from "./HomeBanner.module.css";

export const HomeBanner = () => {
  return (
    <section className={clsx("container", homeBannerStyles['home-banner'])}>
      <div className={homeBannerStyles['left']}>
        <div className={homeBannerStyles['text']}>
          <h1>
            Every <br />
            <span>Review <video muted autoPlay controls={false} src="/videos/-2c89-4fbd-ab74-5e0d1fd3336b.mp4" alt="" /></span> <br />
            Matter
          </h1>
          <p>Check Ratings of Businesses, Read Reviews & Buy</p>
        </div>

        <SearchComponent />
      </div>

      <div className={homeBannerStyles['right']}>
        <img src="/img/image.png" alt="" />
      </div>
    </section>
  );
};
