import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import dashboardStyles from "./Dashboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAsync } from "../../actions/user.action";
import { getUserAvatar, getUserLongName, showToast } from "../../utils";

export const Dashboard = () => {
    const dispatch = useDispatch();
    const { profile } = useSelector(({ User }) => User);
    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        country: '',
        phone: '',
        country_code: '',
    })

    useEffect(() => {
        if (!profile) return;
        setValues({
            first_name: profile.first_name || '',
            last_name: profile.last_name || '',
            country: profile.country || '',
            phone: profile.phone || '',
            country_code: profile.country_code || '',
        })
    }, [profile])

    const onProfileChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleUpdateProfile = () => {
        dispatch(updateUserAsync(values, (data) => {
            showToast("Profile updated successfully", "success")
        }))
    }

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    useEffect(() => {
        document.title = "Qolty Dashboard";
    }, []);

    if (!profile) return null

    return (
        <>
            <NavWithSearch />

            <section className={dashboardStyles['user-details']}>
                <div className={dashboardStyles['user-avatar']}>
                    <img src={getUserAvatar(profile)} alt="" />
                </div>
                <div className={dashboardStyles['user-name-and-location']}>
                    <div className={dashboardStyles['user-name']}>{getUserLongName(profile)}</div>
                    {/* {
                        profile.country
                            ?
                            ( */}
                    <div className={dashboardStyles['user-location']}>
                        <img src="/img/ep-location.svg" alt="" />
                        <span>{profile.country || <i>Unknown</i>}</span>
                    </div>
                    {/* )
                            :
                            null
                    } */}

                </div>
                <div className={dashboardStyles['user-stats']}>
                    <div className={dashboardStyles['user-stat']}>
                        <div className={dashboardStyles['user-stat-number']}>1</div>
                        <div className={dashboardStyles['user-stat-name']}>
                            Revews
                        </div>
                    </div>
                    <div className={dashboardStyles['user-stat']}>
                        <div className={dashboardStyles['user-stat-number']}>20</div>
                        <div className={dashboardStyles['user-stat-name']}>Read</div>
                    </div>
                    <div className={dashboardStyles['user-stat']}>
                        <div className={dashboardStyles['user-stat-number']}>0</div>
                        <div className={dashboardStyles['user-stat-name']}>Useful</div>
                    </div>
                </div>
            </section>

            <section className={dashboardStyles['user-container']}>
                <div className={dashboardStyles['left']}>
                    <h2>Account Management</h2>

                    <div className={dashboardStyles['settings-card']}>
                        {/* <div className={dashboardStyles['input-group']}>
                            <div className={dashboardStyles['label']}>Your Display Picture</div>
                            <button className={dashboardStyles['upload-photo']}>
                                Upload Your Photo
                            </button>
                        </div> */}
                        <div className={dashboardStyles['input-group']}>
                            <div className={dashboardStyles['label']}>First Name</div>
                            <div className={dashboardStyles['input-con']}>
                                <input className={dashboardStyles['input']} value={values.first_name || ''} onChange={onProfileChange} type="text" name="first_name" id="" />
                            </div>
                        </div>
                        <div className={dashboardStyles['input-group']}>
                            <div className={dashboardStyles['label']}>Last Name</div>
                            <div className={dashboardStyles['input-con']}>
                                <input className={dashboardStyles['input']} value={values.last_name || ''} onChange={onProfileChange} type="text" name="last_name" id="" />
                            </div>
                        </div>
                        <div className={dashboardStyles['input-group']}>
                            <div className={dashboardStyles['label']}>Phone</div>
                            <div className={dashboardStyles['input-con']}>
                                <input className={dashboardStyles['input']} value={values.phone || ''} onChange={onProfileChange} type="text" name="phone" id="" />
                            </div>
                        </div>
                        <div className={dashboardStyles['input-group']}>
                            <div className={dashboardStyles['label']}>Country</div>
                            <div className={dashboardStyles['input-con']}>
                                <input className={dashboardStyles['input']} value={values.country || ''} onChange={onProfileChange} type="text" name="country" id="" />
                            </div>
                        </div>
                        <div className={dashboardStyles['apply-updates']} onClick={handleUpdateProfile}>
                            Apply Updates
                        </div>
                    </div>
                </div>
                <div className={dashboardStyles['right']}>
                    <img src="/img/man-holding-gear-wheel-removebg-preview.png" alt="" />
                </div>
            </section>

            <Footer />
        </>
    );
};
