import { querifyObject } from "../utils";
import { HttpMethod } from "./baseHttpService";

export default class CompanyService {
  constructor(
    apiService
  ) {
    this.apiService = apiService;
  }

  async getCompanies(params = {}) {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `company?${querifyObject(params)}`,
    });
    return response;
  }

  async getCompany(slug, brief = false) {
    const query = brief ? '?type=brief' : '';
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `company/${slug}${query}`,
    });
    return response;
  }
}


