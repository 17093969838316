.revolution {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  position: relative;
  z-index: 1;
}

.revolution h2 {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.30);
  font-family: Outfit;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 110px;
  text-transform: capitalize;
  width: 980px;
  margin-bottom: 135px;
}

.revolution h2 span {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.30);
  font-family: Outfit;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 110px;
  text-transform: capitalize;


  background: linear-gradient(180deg, rgba(120, 255, 214, 0.60) 42.25%, #A8FF78 57.3%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.revolution p {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  max-width: 1226px;
  margin-bottom: 96px;
}

.revolution .slides {
  width: 100%;
  padding: 65px 100px;
  background: linear-gradient(90deg, #2F5676 0.01%, #4C4476 128.18%);
  box-shadow: 25px 25px 2rem 0.0625rem #0c0c0d66;
  position: relative;
}

.revolution .slides .next {
  position: absolute;
  right: 17px;
  top: calc(100% / 2 - (17px / 2));
  cursor: pointer;
}

.revolution .slides .prev {
  position: absolute;
  top: calc(100% / 2 - (17px / 2));
  left: 17px;
  cursor: pointer;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.revolution .slides .slide {
  /* background-image: url(three.07958740.svg); */
  background-size: 100% 100%;
}

.revolution .image-card {
  height: 617px;
  width: 386px;
  border-radius: 15px;
  border: 5px #fff solid;
  background: #4C4476;
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-top: 24px;
}

.revolution .card-text {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 321px;
}
