import { HttpMethod } from "./baseHttpService";

export default class GeneralService {
  constructor(
    apiService
  ) {
    this.apiService = apiService;
  }

  async getLandingData() {
    const response = await this.apiService.request({
      method: HttpMethod.GET,
      url: `home`,
    });
    return response;
  }

  async companyFormSubmission(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      url: `home/company-form-submission`,
      payload: data
    });
    return response;
  }
}
