import React, { useEffect } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import aboutUsStyles from "./AboutUsPage.module.css";

export const AboutUsPage = () => {
    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    useEffect(() => {
        document.title = "About Qolty";
      }, []);

    return (
        <>
            <NavWithSearch />

            <section className={aboutUsStyles['banner']}>
                <div className={aboutUsStyles['left']}>
                    <h1>
                        How <span>Qolty</span> Came To Be
                    </h1>
                </div>

                <div className={aboutUsStyles['right']}>
                    <video muted autoPlay controls={false} src="/videos/-9661-4ae4-8d61-b807fc630e29.mp4" alt="" />
                </div>
            </section>

            <section className={aboutUsStyles['first-row']}>
                <div className={aboutUsStyles['img']}>
                    <img src="/img/12629327-reviews-1-converted-1.png" alt="" />
                </div>
                <div className={aboutUsStyles['description']}>
                    QoLty provides detailed and informative product
                    reviews online to improve consumers'
                    quality of life. <br /><br />

                    The website offers extensive, impartial product
                    reviews to help people make educated
                    purchases
                </div>
            </section>

            <section className={aboutUsStyles['our-impressive-stats']}>
                <div className={aboutUsStyles['stat']}>
                    <img src="/img/o-harika-3.png" alt="" />
                    <div className={aboutUsStyles['stat-value-and-name']}>
                        <span className={aboutUsStyles['stat-value']}>18,000</span>
                        Websites Reviewed
                    </div>
                </div>
                <div className={aboutUsStyles['stat']}>
                    <img src="/img/o-harika-3.png" alt="" />
                    <div className={aboutUsStyles['stat-value-and-name']}>
                        <span className={aboutUsStyles['stat-value']}>20 Lakh</span>
                        Customer Reviews
                    </div>
                </div>
                <div className={aboutUsStyles['stat']}>
                    <img src="/img/o-harika-3.png" alt="" />
                    <div className={aboutUsStyles['stat-value-and-name']}>
                        <span className={aboutUsStyles['stat-value']}>150+</span>
                        Trusted Nations
                    </div>
                </div>
            </section>

            <section className={aboutUsStyles['second-row']}>
                <div className={aboutUsStyles['description']}>
                    <div className={aboutUsStyles['row-animation']}>
                        <video muted autoPlay controls={false} src="/videos/-ddb5-40b0-8dcf-f5f8e1ba05bf.mp4" alt="" />
                    </div>
                    Our Mission is to empower customers with honest, credible, and insightful evaluations to make educated choices. <br /><br />
                    We want to be the top platform for honest feedback and thorough analysis to help people reliably assess goods, services, and experiences.
                    purchases
                </div>
                <div className={aboutUsStyles['img']}>
                    <img src="/img/blue-webinar-instagram-post-1-1.png" alt="" />
                </div>
            </section>

            <section className={aboutUsStyles['third-row']}>
                <div className={aboutUsStyles['img']}>
                    <img src="/img/blue-webinar-instagram-post-1.png" alt="" />
                </div>
                <div className={aboutUsStyles['description']}>
                    <div className={aboutUsStyles['row-animation']}>
                        <video muted autoPlay controls={false} src="/videos/-ddb5-40b0-8dcf-f5f8e1ba05bf.mp4" alt="" />
                    </div>
                    We aim to build trust and knowledge so every review makes the market more educated and fairer. <br /><br />
                    Qolty strives to improve customer feedback and review industry quality and dependability via innovation and honesty.
                </div>

            </section>


            <Footer />
        </>
    );
};
