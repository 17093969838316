.reviews {
  background-image: url(../../../static/img/background-of-review-slides.png);
  background-position: 50% 50%;
  /* background-size: cover; */

  --review-padding-top: 116px;

  padding: var(--review-padding-top) 38px;
  position: relative;

  margin-bottom: 390px;
}

.reviews .quotation {
  background-color: #2B5876;

  --quotation-size: 78px;

  height: var(--quotation-size);
  width: var(--quotation-size);
  border-radius: 100%;

  top: calc(var(--review-padding-top) - (var(--quotation-size) / 2));
  left: calc(var(--review-padding-top) - (var(--quotation-size) / 2));
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews .quotation span {
  color: #FFF;
  text-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.30);
  font-family: Outfit;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  display: flex;
  margin-top: 35px;
  vertical-align: middle;
}

.reviews .review-inner {
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(26px);
  padding: 81px 17px 46px 17px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 40px;
  align-items: center;
  /* height: 400px; */
}

.reviews .review-extra {
  /* margin-top: 40px; */
}

.reviews .review-inner h3.title {
  color: #FFF;
  font-family: Outfit;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 39px;
}

.reviews .review-inner .subtitle {
  color: #FFF;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 36px;
}

.reviews .review-inner button {
  font-size: 20px;
  line-height: 1;

  border-radius: 10px;
  background: rgba(45, 86, 118, 0.95);
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
  border: none;

  color: #ffffff;
  text-decoration: none;
  padding: 19px 35px;
}

.reviews .review-boxes {
  grid-column: 2 / span 3;
  /* background-color: #FFF; */
  /* height: 200px; */

  /* display: flex;
  gap: 28px; */

}

.reviews .review-box {
  border-radius: 15px;
  background: #4E4376;
  /* flex: 1; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: auto;

  /* max-width: 33%; */
}

.reviews .review-img {
  height: 145px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 18px;
}

.reviews .review-author {
  color: #FFF;
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 18px;
}

.reviews .review-author a {
  color: #FFF;
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.reviews .review-author-title {
  color: #FFF;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 35px;
}

.reviews .review-author-title a {
  color: #FFF;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 35px;
}

.reviews .review-stars {
  display: flex;
  gap: 15px;
  margin-bottom: 28px;
}

.reviews .review-text {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 45px;

  padding: 0px 15px;

  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

}

.reviews .review-navigation {
  /* background-color: #FFF; */
  padding: 29px 62px;
  grid-column: 2 / span 3;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.reviews .review-nav-arrows {
  display: flex;
  gap: 58px;
}

.reviews .review-nav-arrows svg {
  cursor: pointer;
  color: white;
}

.reviews .review-nav-bullets {
  display: flex;
  gap: 10px;
  align-items: center;
  width: auto !important;
}

.reviews .review-nav-bullets .bullet {
  cursor: pointer;
  width: 17px;
  height: 17px;
  border-radius: 17px;
  border-radius: 17px;
  background: rgba(0, 0, 0, 0.55);
}

.reviews .review-nav-bullets .bullet.active {
  background: #666676;
}