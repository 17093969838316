import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SearchComponent } from "../SearchComponent";
import navWithSearchStyles from "./NavWithSearch.module.css";
import { useSelector } from "react-redux";

export const NavWithSearch = () => {
  const { profile } = useSelector(({ User }) => User);

  return (
    <section className={navWithSearchStyles['nav-with-search']}>
      <div className={navWithSearchStyles['logo']}>
        <Link to="/">
          <img src="/img/logo.png" />
        </Link>
      </div>

      <div className={navWithSearchStyles['links']}>
        <SearchComponent className={navWithSearchStyles['search-component']} />
        <Link to="/categories">Categories</Link>
        {!profile ? <Link to="/login">Login</Link> : null}
        <Link className={navWithSearchStyles['for-business']} to="/for-businesses">For Businesses</Link>
      </div>
    </section>
  );
};
