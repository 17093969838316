import React from "react";
import clsx from "clsx";
import getThroughStyles from "./GetThrough.module.css";
import { useNavigate } from "react-router-dom";

export const GetThrough = () => {
  const navigate = useNavigate()

  return (
    <section className={clsx("x-wide-container", getThroughStyles['get-through'])}>
      <div className={getThroughStyles['left-banner']}>
        <img src="/img/rectangle-20.svg" alt="" />
      </div>

      <div className={getThroughStyles['right']}>
        <h2><span>Get</span> Through</h2>
        <p>Qolty is a go-to source review platform. Share your experiences to make someone’s choices easy in this competitive world and uplift your organisations.</p>
        <button onClick={() => navigate('/for-businesses')}>Find Out More</button>
      </div>
    </section>
  );
};
