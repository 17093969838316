import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import resetPasswordStyles from "./ResetPassword.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { resetPasswordAsync, verifyAsync } from "../../actions/auth.action";
import base64url from "base64-url";

export const ResetPassword = () => {
    const initialState = {
        email: '',
        code: '',
        newPassword: '',
        confirmNewPassword: '',
    }
    const [state, setState] = useState(initialState)
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()

    const location = useLocation()
    const navigate = useNavigate()

    const params = useParams()

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(resetPasswordAsync(state, () => {
            setSuccess(true)
        }, () => {
            // setSuccess(true)
        }))
    }

    useEffect(() => {
        try {
            const json = atob(base64url.unescape(params.encoded))
            const { email, code } = JSON.parse(json)
            setState({ ...initialState, email, code })
        } catch (error) {
            console.log(error)
        }
    }, [location.pathname, params])

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    useEffect(() => {
        document.title = "Qolty - Reset Password";
    }, []);

    return (
        <>
            <NavWithSearch />

            <section className={resetPasswordStyles['login-container']}>
                {/* <h1>Look through. Write down. Discover Organizations</h1> */}

                <div className={resetPasswordStyles['form']}>
                    <div className={resetPasswordStyles['left']}>
                        <img src="/img/login-banner.png" alt="" />
                    </div>
                    {
                        success
                            ?
                            <div className={resetPasswordStyles['right']}>
                                <h3>Reset Password</h3>

                                <p className={resetPasswordStyles['notice']}>Your password was successfully reset. You can now login with the email and the new password provided.</p>
                                <button className={resetPasswordStyles['login']} onClick={() => navigate('/login')}>
                                    Sign In
                                </button>
                            </div>
                            :
                            <div className={resetPasswordStyles['right']}>
                                <h3>Reset Password</h3>

                                <p className={resetPasswordStyles['form-help-text']}>Input your email below to receive instructions.</p>

                                <div className={resetPasswordStyles['input-group']} style={{ marginBottom: 17 }}>
                                    <label htmlFor="">Code</label>
                                    <div className={resetPasswordStyles['input-with-icon']}>
                                        <input
                                            type="text"
                                            name="code"
                                            value={state.code}
                                            onChange={onChange}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className={resetPasswordStyles['input-group']} style={{ marginBottom: 17 }}>
                                    <label htmlFor="">New Password</label>
                                    <div className={resetPasswordStyles['input-with-icon']}>
                                        <input
                                            type="text"
                                            name="newPassword"
                                            value={state.newPassword}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>

                                <div className={resetPasswordStyles['input-group']} style={{ marginBottom: 17 }}>
                                    <label htmlFor="">Confirm New Password</label>
                                    <div className={resetPasswordStyles['input-with-icon']}>
                                        <input
                                            type="text"
                                            name="confirmNewPassword"
                                            value={state.confirmNewPassword}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>

                                <button className={resetPasswordStyles['login']} onClick={onSubmit}>
                                    Reset Password
                                </button>

                                <div className={resetPasswordStyles['divider']}>
                                    <div className={resetPasswordStyles['divider-line']}></div>
                                    <span className={resetPasswordStyles['divider-text']}>OR</span>
                                    <div className={resetPasswordStyles['divider-line']}></div>
                                </div>

                                <button className={clsx(resetPasswordStyles['outlined-button'], resetPasswordStyles['signup'])} onClick={() => navigate('/login')}>
                                    Back to login page
                                </button>
                            </div>
                    }
                </div>
            </section>

            <Footer />
        </>
    );
};
