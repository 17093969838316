.breadcrumbs {
    display: flex;
    align-items: center;
    padding: 55px 100px;
}

.breadcrumbs a {
    color: #000;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-right: 8px;
}

.breadcrumbs span {
    margin-right: 8px;
}

.company-details {
    background: #FFF;
    padding: 40px 100px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.company-details .company-logo {
    border-radius: 15px;
    background: #F4F3FB;
    padding: 10px;
    display: flex;
    align-items: center;
}

.company-details .company-logo img {
    width: 240px;
}

.company-details .general-detail {
    flex: 1;
    max-width: 700px;
}

.company-details .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.company-details .top-row h2 {
    color: #000;
    font-family: Outfit;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
}

.company-details .top-row img {
    height: 25px;
}

.company-details .bottom-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.company-details .bottom-row .verified {
    display: flex;
    align-items: center;

    color: #000;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.company-details .top-row img {
    height: 25px;
}

.company-details .website {
    /* position: relative; */
    background-image: url('../../../static/img/Rectangle 36.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25px 30px 55px;
    text-align: center;
}

.company-details .website .address {
    color: var(--Schemes-On-Primary, #FFF);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 15px;
    text-wrap: nowrap;
    line-height: 1;
}

.company-details .website a {
    color: #C3F8F1;
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
}

.company-review-container {
    display: flex;
    gap: 33px;
    padding: 100px;
}

.company-review-container .left {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.company-review-container .write-review {
    border-radius: 20px;
    background: #FFF;
    padding: 12px 8px 12px;
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    /* just */
}


.company-review-container .write-review img {
    width: 55px;
    margin-right: 30px;
}

.company-review-container .write-review input {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    border: none;
    outline: none;
    flex: 1;
    margin-right: 10px;
}

.company-review-container .write-review .stars {
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.company-review-container .write-review .stars svg {
    margin-right: 10px;
}

.company-review-container .review-summary {
    border-radius: 20px;
    background: #FFF;
    padding: 34px 54px 45px 34px;
    margin-bottom: 60px;
}

.company-review-container .review-summary .divider {
    /* width: 692px; */
    height: 1px;
    background: rgba(0, 0, 0, 0.17);
    margin-top: 24px;
    margin-bottom: 40px;
}

.company-review-container .review-summary .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.company-review-container .review-summary .title-bar h3 {
    color: #000;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;

    display: flex;
    align-items: center;
}

.company-review-container .review-summary .title-bar div {
    color: #000;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
}

.company-review-container .review-summary .title-bar svg {
    height: 32px;
}

.company-review-container .review-summary .review-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.company-review-container .review-summary .review-row:last-child {
    margin-bottom: 0px;
}

.company-review-container .review-summary .review-row .review-stars {
    display: flex;
    gap: 15px;
}

.company-review-container .filter-and-sort {
    border-radius: 20px;
    background: var(--Schemes-On-Primary, #FFF);
    padding: 29px 15px 24px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
}

.company-review-container .filter-and-sort .filter {
    display: flex;
    align-items: center;
    gap: 15px;

    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}

.company-review-container .filter-and-sort .sort {
    display: flex;
    align-items: center;
    gap: 35px;

    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    /* color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400; */
}

.company-review-container .filter-and-sort .sort-select {
    position: relative;
    border-radius: 10px;
    border: 1px solid #000;
    background: var(--Schemes-On-Primary, #FFF);
    padding: 18.5px 52px 18.5px 22px;
}

.company-review-container .reviews-card {
    display: flex;
    flex-direction: column;
    gap: 66px;
}

.company-review-container .right {
    width: 400px;
    display: flex;
    flex-direction: column;
}

.company-review-container .right .banner {
    margin-bottom: 43px;
}

.company-review-container .right .banner img {
    width: 100%;
}

.company-review-container .right .about-company {
    border-radius: 20px;
    background: var(--Schemes-On-Primary, #FFF);
    padding: 36px 44px 25px 44px;
    margin-bottom: 83px;
}

.company-review-container .right .about-company h3.about-heading {
    color: #000;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 38px;
}


.company-review-container .right .about-company .about-text {
    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.company-review-container .right .about-company .divider {
    /* width: 692px; */
    height: 1px;
    background: rgba(0, 0, 0, 0.17);
    margin-top: 37.5px;
    margin-bottom: 20.5px;
}

.company-review-container .right .about-company h3.contact-heading {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 21px;
}

.company-review-container .right .about-company .contact-location {
    display: flex;
}

.company-review-container .right .about-company .contact-address {
    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.company-review-container .right .about-company .contact-location .geo-icon {
    height: 15px;
    margin-right: 28px;
}

.company-review-container .right .encounters {
    border-radius: 20px;
    background: var(--Schemes-On-Primary, #FFF);
    padding: 32px 27px 114px 35px;
    margin-bottom: 83px;
}

.company-review-container .right .encounters .encounter-heading {
    color: #000;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    align-items: center;
    justify-content: center;
}

.company-review-container .right .encounters .encounter-heading img {
    height: 38px;
}

.company-review-container .right .encounters .divider {
    /* width: 692px; */
    height: 1px;
    background: rgba(0, 0, 0, 0.17);
    margin-top: 14px;
    margin-bottom: 40px;
}

.company-review-container .right .encounters .encounter-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.company-review-container .right .encounters .encounter {
    border-radius: 20px;
    border: 1px solid #000;
    background: rgba(195, 177, 248, 0.19);
    padding: 24px 16px;

    cursor: pointer;
}

.company-review-container .right .encounters .encounter .header {
    display: flex;
    justify-content: space-between;
}

.company-review-container .right .encounters .encounter .title {
    color: #000;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.company-review-container .right .encounters .encounter.open .title {
    /* font-weight: 600; */
}

.company-review-container .right .encounters .encounter .dropdown-icon {
    display: flex;
}

.company-review-container .right .encounters .encounter .dropdown-icon img {
    height: 24px;
}

.company-review-container .right .encounters .encounter .answer {
    color: #000;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 25px;
    flex: 0 0 100%;
    /* width: 100%; */
}


.write-review-card {
    border-radius: 20px;
    background: var(--Schemes-On-Primary, #FFF);
    padding: 40px 55px;
    margin-bottom: 45px;

    /* display: flex;
    flex-direction: column;
    gap: 30px; */
}

.write-review-card h3 {
    color: #000;
    align-items: center;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}


.input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 35px;

    /* justify-content: stretch; */
}

.input-group .label {
    color: #000;
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 18px;
}

.input-group .input-con {
    width: 100%;
}

.input-group .input {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #000;
    background: var(--Schemes-On-Primary, #FFF);

    font-size: 20px;
    line-height: 1;
    padding: 20px;
}

.input-group textarea {
    width: 100%;
    border-radius: 20px;
    border: 1px solid #000;
    background: var(--Schemes-On-Primary, #FFF);

    font-size: 20px;
    line-height: 1;
    padding: 20px;

    resize: none;
}

.submit-review {
    /* width: 100%; */

    border-radius: 20px;
    background: #6F79D2;
    border: none;
    padding: 20px 50px;

    color: var(--Schemes-On-Primary, #FFF);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    cursor: pointer;
}

.input-stars {
    display: flex;
    gap: 15px;
    cursor: pointer;
}