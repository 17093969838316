import React, { useEffect, useMemo, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import categoryPageStyles from "./CategoryPage.module.css";
import Select from 'react-select'
import { CompanyCard } from "../../components/CompanyCard";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesAsync } from "../../actions/category.action";
import { getCompaniesAsync } from "../../actions/company.action";
import { useParams } from "react-router-dom";
import { renderPagination } from "../../utils";

export const CategoryPage = () => {
    const dispatch = useDispatch()
    const [loaded, setLoaded] = useState(false)
    const [state, setState] = useState({
        companies: [],
        per_page: 10,
        offset: 0,
        to: 0,
        last_page: 0,
        page: 1,
        current_page: 1,
        from: 0,
        total: 0,
    })
    const { slug } = useParams();

    const { categories } = useSelector(({ Category }) => Category)
    const category = useMemo(() => categories.find(e => e.slug === slug), [categories])

    useEffect(() => {
        console.log("Page (re)loaded")
        dispatch(getCategoriesAsync());
    }, [])

    const [sortBy, setSortBy] = useState({ value: 'alphabetical', label: 'Alphabetical' });

    const handlePageChange = (page) => {
        setState((state) => ({
            ...state,
            page: page
        }))
    }

    useEffect(() => {
        setLoaded(false);
        dispatch(getCompaniesAsync({ category: slug, page: 1, per_page: state.per_page, sort_by: sortBy.value }, (resp) => {
            const { data, ...pagination } = resp
            console.log(data)
            setState({
                ...pagination,
                // [...data, ...data, ...data]
                companies: data
            });
            setLoaded(true);
        }))
    }, [slug, sortBy])

    useEffect(() => {
        if (!loaded) return
        setLoaded(false);
        dispatch(getCompaniesAsync({ category: slug, page: state.page, per_page: state.per_page, sort_by: sortBy.value }, (resp) => {
            const { data, ...pagination } = resp
            console.log(data)
            setState({
                ...pagination,
                companies: data
            })
            setLoaded(true);
        }))
    }, [state.page])

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [slug, state, loaded])

    useEffect(() => {
        document.title = category ? `Best Companies in ${category.title} on Qolty` : "Best Companies in  Catgeory on Qolty";
    }, [category]);

    const options = [
        { value: 'alphabetical', label: 'Alphabetical' },
        { value: 'highest-reviews', label: 'Highest Reviews' },
        { value: 'lowest-reviews', label: 'Lowest Reviews' },
        { value: 'most-reviewed', label: 'Most Reviewed' },
        { value: 'least-reviewed', label: 'Least Reviewed' },
    ]

    if (!category) return null;

    return (
        <>
            <NavWithSearch />

            <section className={categoryPageStyles['banner']}>
                <div className={categoryPageStyles['left']}>
                    <h1>
                        Leading <span>{category.title}</span> Platforms
                    </h1>
                </div>

                <div className={categoryPageStyles['right']}>
                    <video muted autoPlay controls={false} src="/videos/-0c0f-4cbc-9e16-ad387c2cd394.mp4" alt="" />
                </div>
            </section>

            <section className={categoryPageStyles['results']}>
                <div className={categoryPageStyles['result-header']}>
                    <div className={categoryPageStyles['result-summary']}>
                        <h2>{category.title} Platforms</h2>
                        <span>{state.companies.length > 1 ? `1-${state.companies.length}` : `${state.companies.length}`} of {state.total} Results</span>
                    </div>
                    <div className={categoryPageStyles['result-filter']}>
                        <span>Sort By</span>
                        <Select value={sortBy} options={options} onChange={(event) => setSortBy(event)} className="category-sort-by" />
                    </div>
                </div>

                <div className={categoryPageStyles['result-list']}>

                    {
                        loaded && state.companies.length >= 3
                            ?
                            <div className={categoryPageStyles['graphic']}>
                                <img src="/img/a6f78a28857760e3e4309bf9bfa66193.png" alt="" />
                            </div>
                            :
                            null
                    }

                    {
                        !loaded
                            ?
                            <div>Loading</div>
                            :
                            state.companies.length > 0
                                ?
                                state.companies.map((company, index) => (
                                    <CompanyCard company={company} />
                                ))
                                :
                                <div>No match found for this category</div>
                    }

                </div>

                {
                    state.last_page
                        ?
                        <div className={categoryPageStyles['result-pagination']}>
                            {renderPagination(state.page, state.last_page, handlePageChange, categoryPageStyles)}
                        </div>
                        :
                        null
                }

            </section>

            <Footer />
        </>
    );
};
