import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import userProfileStyles from "./UserProfile.module.css";
import { ReviewCard } from "../../components/ReviewCard";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserByIdAsync } from "../../actions/user.action";
import { getUserReviewsAsync } from "../../actions/review.action";
import { getUserAvatar, getUserIdFromSlug, getUserLongName } from "../../utils";

export const UserProfile = () => {
    const initialState = {
        user: null,
        reviews: [],
        last_page: 1,
        page: 1,
        offset: 0
    };
    const { slug } = useParams();
    const [page, setPage] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [state, setState] = useState(initialState);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [id, setId] = useState(null);

    useEffect(() => {
        try {
            setId(getUserIdFromSlug(slug))
        } catch (error) {
            navigate('/invalid-page')
            // go to 404 page
        }
    }, [slug])

    useEffect(() => {
        if (!id) return
        setLoaded(false);
        setState(initialState);
        dispatch(getUserByIdAsync(id, (data) => {
            setState({
                ...state,
                ...data,
            })
            setLoaded(true);
        }))
    }, [id])

    useEffect(() => {
        if (loaded)
            dispatch(getUserReviewsAsync(id, page, 20, (data) => {
                const { pagination: { data: reviews, last_page, page, offset, total } } = data;
                console.log(data)
                setState({
                    ...state,
                    reviews,
                    last_page,
                    page,
                    offset,
                    total,
                })
            }))
    }, [loaded, page])

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    useEffect(() => {
        document.title = state.user ? `${getUserLongName(state.user)} Reviews` : "User Reviews";
    }, [state.user]);

    if (!state.user) return null

    return (
        <>
            <NavWithSearch />

            <section className={userProfileStyles['user-details']}>
                <div className={userProfileStyles['user-avatar']}>
                    <img src={getUserAvatar(state.user)} alt="" />
                </div>
                <div className={userProfileStyles['user-name-and-location']}>
                    <div className={userProfileStyles['user-name']}>{getUserLongName(state.user)}</div>
                    {
                        state.country
                            ?
                            (
                                <div className={userProfileStyles['user-location']}>
                                    <img src="/img/ep-location.svg" alt="" />
                                    <span>{state.country}</span>
                                </div>
                            )
                            :
                            null
                    }

                </div>
            </section>

            <section className={userProfileStyles['user-container']}>
                <div className={userProfileStyles['left']}>
                    <h2>Reviews</h2>
                    {/* <div className={userProfileStyles['subtitle']}>Review Of Workday.com</div> */}
                    {
                        state.reviews.length > 0
                            ?
                            state.reviews.map((review, index) => (<ReviewCard className={userProfileStyles['review-card']} review={review} key={index} />))
                            :
                            <div>No reviews yet</div>
                    }
                </div>
                <div className={userProfileStyles['right']}>
                    <img src="/img/feedback-3-2.png" alt="" />
                </div>
            </section>

            <Footer />
        </>
    );
};
