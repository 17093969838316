.nav-with-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 28px 115px 28px 115px;
  background: linear-gradient(90deg, #2E5676 0%, #4B4476 100%);
}

.nav-with-search .logo {
  margin-right: 48px;
}

.nav-with-search .logo img {
  height: 53px;
}

.nav-with-search .search-component {
  margin-right: 40px;
  flex: 1;
}

.nav-with-search .links {
  display: flex;
  align-items: center;
  flex: 1;
}

.nav-with-search .links a {
  font-size: 20px;
  color: #ffffff;
  text-decoration: none;
  margin-right: 40px;
  /* padding: 0px 20px; */
}

.nav-with-search .links a.for-business {
  font-size: 20px;

  border-radius: 10px;
  background: rgba(45, 86, 118, 0.95);
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
  border: none;

  color: #ffffff;
  text-decoration: none;
  padding: 19px 28px;

  white-space: nowrap;

  margin-right: 0px;
}

.nav-with-search .links a.for-business:hover {
  text-decoration: underline;
}