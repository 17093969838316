.banner {
    background: linear-gradient(90deg, #2E5676 0.09%, #4B4476 105.27%);
    padding: 28px 77px 30px 115px;

    display: flex;
    align-items: center;
}

.banner h1 {
    color: var(--Schemes-On-Primary, #FFF);
    font-family: Outfit;
    font-size: 87px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.banner h1 span {
    font-family: Outfit;
    font-size: 87px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    background: linear-gradient(182deg, #FF0084 13.04%, #FD8C43 38.65%, #FDBB2D 50.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.left {
    max-width: 600px;
    flex: 1;
}

.right {
    flex: 1;
    padding: 92px 0px 92px 0px;

    display: flex;
    justify-content: center;
}

.right video {
    height: 370px;

    border-radius: 20px;
    box-shadow: 0px 4px 40px 0px #FFF;
}

.first-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 83px 140px 25px 140px;
    gap: 128px;
}

.first-row .description {
    color: #000;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    max-width: 448px;
}

.our-impressive-stats {
    background: var(--Schemes-On-Primary, #FFF);
    padding: 63px 141px 71px;

    display: flex;
    justify-content: space-between;
}

.our-impressive-stats .stat {
    flex: 1;
    display: flex;
    gap: 28px;
}

.our-impressive-stats .stat img {
    height: 52px;
}

.our-impressive-stats .stat .stat-value-and-name {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #000;
    font-family: Outfit;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.our-impressive-stats .stat .stat-value {
    color: #4B4476;
}

.second-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 83px 140px 100px 140px;
    /* gap: 128px; */
}

.second-row img {
    flex: 1;
    max-width: 498px;
}

.second-row .description {
    color: #000;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    max-width: 448px;

    position: relative;
}

.second-row .description .row-animation {
    position: absolute;

    top: -66px;
    left: -33px;
}

.second-row .description .row-animation video {
    width: 66px;
    height: 66px;
    background: transparent;
    mix-blend-mode: darken;
}

.third-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 83px 140px 184px 140px;
    gap: 111px;
}

.third-row img {
    flex: 1;
    max-width: 419px;
}

.third-row .description {
    color: #000;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    max-width: 448px;

    position: relative;
}

.third-row .description .row-animation {
    position: absolute;

    top: -66px;
    right: -33px;
}

.third-row .description .row-animation video {
    width: 66px;
    height: 66px;
    background: transparent;
    mix-blend-mode: darken;
}