import React, { useEffect, useState } from "react";
import reviewCardStyles from "./ReviewCard.module.css";
import clsx from "clsx";
import { getUserAvatar, getUserLongName, getUserSlug } from "../../utils";
import { ReviewStars } from "../ReviewStars";
import { Link } from "react-router-dom";

export const ReviewCard = ({ review, className }) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    const handleClick = () => {
        setIsChecked(!isChecked)
    }

    const date = new Date(review.created_at);

    const formattedDate = date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    return (
        <div className={clsx(reviewCardStyles['review-card'], className)}>
            <div className={reviewCardStyles['user-detail']}>
                <div className={reviewCardStyles['detail-left']}>
                    <img className={reviewCardStyles['user-avatar']} src={getUserAvatar(review.author)} alt="" />
                    <div>
                        <Link to={`/user/${getUserSlug(review.author)}`}>
                            <div className={reviewCardStyles['user-name']}>{getUserLongName(review.author)}</div>
                        </Link>
                        {/* <div className={reviewCardStyles['user-review-count-and-location']}>
                            <div className={reviewCardStyles['user-review-count']}>1 Review</div>
                            <div className={reviewCardStyles['user-location']}><img src="/img/group-7.png" alt="" /> US</div>
                        </div> */}

                    </div>
                </div>
                <div className={reviewCardStyles['detail-right']}>
                    <div className={reviewCardStyles['review-stars']}>
                        <ReviewStars rating={review.rating} starWidth={15.88} />
                    </div>
                    {
                        review.author.linkedin_verified
                            ?
                            <div className={reviewCardStyles['user-linkedin-verified']}>
                                <img src="/img/checked-9709605-2-4.svg" alt="" />
                                <span>LinkedIn Verified</span>
                            </div>
                            :
                            null
                    }

                </div>
            </div>
            <div className={reviewCardStyles['review-content']}>
                <div className={reviewCardStyles['review-title-and-date']}>
                    <h3 className={reviewCardStyles['review-title']}>{review.title}</h3>
                    <div className={reviewCardStyles['review-date']}>Date: {formattedDate}</div>
                </div>
                <div className={reviewCardStyles['review-text']}>
                    {review.review}
                </div>
                <div className={reviewCardStyles['divider']}></div>
                <div className={reviewCardStyles['review-ctas']}>
                    {
                        isChecked
                            ?
                            <div className={reviewCardStyles['cta-like']} style={{ color: '#FFA43C' }} onClick={handleClick}><img src="/img/CompanyPage-vector-4.svg" alt="" /><span>Unlike</span></div>
                            :
                            <div className={reviewCardStyles['cta-like']} onClick={handleClick}><img src="/img/CompanyPage-vector-2.svg" alt="" /><span>Like</span></div>
                    }
                    {/* <div className={reviewCardStyles['cta-share']}><img src="/img/CompanyPage-vector-10.svg" alt="" /><span>Share</span></div> */}
                </div>
            </div>
        </div>
    );
};
