import * as types from './auth.types.js'
import { authService } from '../services'
import { showToast, showErrorMessage, setAuthToken } from '../utils'

export const registerAsync = (
  data,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.SIGN_UP.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await authService.register(data)
      dispatch({ type: types.SIGN_UP.SUCCESS, payload: _data })
      showToast('Signup successful. A verification mail has been sent to the email provided. Kindly follow the instructions', 'info')
      successCallback?.()
    } catch (err) {
      dispatch({ type: types.SIGN_UP.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const verifyAsync = (
  data,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.VERIFY_EMAIL.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await authService.verify(data)
      dispatch({ type: types.VERIFY_EMAIL.SUCCESS, payload: _data })
      // showToast('Verification successful. You can proceed to log in', 'info')
      successCallback?.()
    } catch (err) {
      dispatch({ type: types.VERIFY_EMAIL.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const loginAsync = (
  data,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.SIGN_IN.REQUEST })
    try {
      const { data: { data: _data }, } = await authService.login(data)
      const token = _data.token.token, user = _data.user;
      setAuthToken(_data.token.token)
      dispatch({ type: types.SIGN_IN.SUCCESS, payload: { token, user } })
      successCallback?.(user)
    } catch (err) {
      dispatch({ type: types.SIGN_IN.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const lostPasswordAsync = (
  payload,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.LOST_PASSWORD.REQUEST })
    try {
      const { data: { data } } = await authService.lostPassword(payload)
      dispatch({ type: types.LOST_PASSWORD.SUCCESS, payload: data })
      successCallback?.()
    } catch (err) {
      dispatch({ type: types.LOST_PASSWORD.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const resetPasswordAsync = (
  payload,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.RESET_PASSWORD.REQUEST })
    try {
      const { data: { data } } = await authService.resetPassword(payload)
      dispatch({ type: types.RESET_PASSWORD.SUCCESS, payload: data })
      successCallback?.()
    } catch (err) {
      dispatch({ type: types.RESET_PASSWORD.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}
