.banner {
    width: 1102px;
    display: block;
    margin: 200px auto 0px;
    padding: 0;
}

.heading {
    color: #000;
    font-family: Outfit;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 60px;
}

.subtitle {
    color: #000;
    font-family: Outfit;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 200px;
}