.company-card {
    display: flex;
    flex-direction: column;

    border-radius: 32px;
    background: var(--Schemes-On-Primary, #FFF);

    padding: 27px 30px;
}

.company-card h3 {
    color: #000;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;

    margin-bottom: 20px;
}

.company-card .review-stats {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}


.company-card .review-stats .stars {
    margin-right: 25px;
    display: flex;
    gap: 9px;
}

.company-card .review-stats .values {
    display: flex;
    gap: 10px;
    align-items: center;

    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.3;
    text-transform: capitalize;
}

.company-card .company-location {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    text-transform: capitalize;
    margin-bottom: 25px;
}

.company-card .company-about {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    text-transform: capitalize;
}