import { HttpMethod } from "./baseHttpService";

export default class AuthService {
  constructor(
    apiService
  ) {
    this.apiService = apiService;
  }

  async register(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `user/signup`,
    });
    return response;
  }

  async verify(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `user/verify`,
    });
    return response;
  }

  async login(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `user/signin`,
    });
    return response;
  }

  async lostPassword(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `user/lost-password`,
    });
    return response;
  }

  async resetPassword(data) {
    const response = await this.apiService.request({
      method: HttpMethod.POST,
      payload: data,
      url: `user/reset-password`,
    });
    return response;
  }
}


