import React from "react";
import categoriesStyles from "./Categories.module.css";
import { Link } from "react-router-dom";

export const Categories = ({ categories }) => {
  return (
    <div className={categoriesStyles['categories']}>
      {
        categories.map((category, index) => (
          <Link to={`/categories/${category.slug}`} title={category.title}>
            <div className={categoriesStyles['category']}>
              <img alt="Garden image" src={category.featured_image} />
              <span>{category.title}</span>
            </div>
          </Link>
        ))
      }
    </div>
  );
};
