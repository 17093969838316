import React, { useEffect } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import error404PageStyles from "./Error404Page.module.css";
import { useLocation } from "react-router-dom";

export const Error404Page = () => {
    const location = useLocation();

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [location.pathname])

    useEffect(() => {
        document.title = `Page not found`;
    }, [location.pathname]);

    return (
        <>
            <NavWithSearch />

            <div>
                <img className={error404PageStyles['banner']} src="/img/a5034a1899289737d5d4817aa07aaf69.png" alt="Error 404" />
                <h1 className={error404PageStyles['heading']}>Page Not Found!</h1>
                <p className={error404PageStyles['subtitle']}>
                    We are sorry, the page you are looking for could not be found<br />
                    Please go back to homepage
                </p>
            </div>

            <Footer />
        </>
    );
};
