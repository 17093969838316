import { toast } from 'react-toastify';
import cookies from "js-cookie";
import clsx from 'clsx';
import { v4 } from 'uuid';

export const generateActions = (action) => {
    action = action.toUpperCase();
    return {
        REQUEST: `${action}_REQUEST`,
        SUCCESS: `${action}_SUCCESS`,
        FAILURE: `${action}_FAILURE`,
    };
};

export const getUserShortName = (user) => user?.first_name || user?.last_name || ''

export const getUserLongName = (user) => {
    const first_name = user?.first_name || '';
    const last_name = user?.last_name || '';
    return `${first_name} ${last_name}`.trim()
}

const userIdbase = 'db1e7eae-4471-40b2-placeholder-ef72ccb2adf7';

export const getUserSlug = (user) => {
    const uuid = 'db1e7eae-4471-40b2-placeholder-ef72ccb2adf7' || v4();
    const parts = uuid.split('-');
    let base64 = btoa(String(user.id));
    while (base64.endsWith('='))
        base64 = base64.substring(0, base64.length - 1)

    parts[parts.length - 2] = base64;

    return parts.join('-');
}

export const getUserIdFromSlug = (slug = '') => {
    const parts = slug.split('-');
    const base64 = parts[parts.length - 2];
    parts[parts.length - 2] = 'placeholder'
    if (userIdbase !== parts.join('-')) throw new Error('Invalid user slug')
    const id = parseInt(atob(base64));
    if (isNaN(id)) throw new Error('Invalid user slug')
    return id
}

export const getUserAvatar = (user, rounded = true) => {
    const params = { background: 'random', color: 'random', name: getUserLongName(user).replace(/[^a-z]/ig, ''), rounded }
    return user.avatar_url || `https://ui-avatars.com/api/?${querifyObject(params)}`
}

export const getRatingText = (rating) => `${rating.toFixed(2)}/5.00`

export const showToast = (message, type) => {
    const props = {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
    }

    switch (type?.toLowerCase()) {
        case "success":
            toast.success(message, {
                position: "top-right",
                ...props
            });
            break;
        case "info":
            toast.info(message, {
                position: "top-right",
                ...props
            });
            break;
        case "loading":
            toast.loading(message, {
                position: "top-right",
                ...props
            });
            break;
        case "warn":
            toast.warn(message, {
                position: "top-right",
                ...props
            });
            break;
        case "error":
            toast.error(message, {
                position: "top-right",
                ...props
            });
            break;
        default:
            toast.info(message, {
                position: "top-right",
                ...props
            });
            break;
    }
};

function parseErrorMessage(data) {
    if (typeof data?.message === "string" && data?.message !== "") {
        return data.message;
    } else if (data?.message instanceof Array && data.message.length > 0) {
        return data?.message[0];
    } else if (typeof data?.error === "string" && data?.error !== "") {
        return data.error;
    } else if (data?.error instanceof Array) {
        return data?.error[0] || "An error occurred, contact the webmasters";
    } else {
        return "An error occurred, contact the webmasters";
    }
}

export const showErrorMessage = (err) => {
    const message = parseErrorMessage(err.getData ? err.getData() : null);
    return showToast(message, "error");
};

export const getAuthToken = () => cookies.get('__session');

export const setAuthToken = (value) => cookies.set('__session', value, { path: "/", expires: new Date(Date.now() + (1000 * 60 * 30)) });

export const clearAuthToken = () => cookies.remove('__session', { path: "/" });

export const logout = () => {
    clearAuthToken()
    window.location.href = "/login";
};

export const querifyObject = (obj) => {
    return Object.keys(obj).map(e => `${e}=${obj[e]}`).join('&')
}

export const renderPagination = (page, last_page, select = () => { }, styles = {}) => { // previous, disabled, page, active, next
    const pages = [];
    const maxVisiblePages = 7;

    // Previous Button
    pages.push(
        <span
            key="previous"
            className={clsx(
                styles['previous'],
                page === 1 && styles['disabled']
            )}
            onClick={() => select(page - 1)}
        >
            Previous
        </span>
    );

    if (last_page <= maxVisiblePages) {
        // Render all pages if the total number of pages is <= maxVisiblePages
        for (let i = 1; i <= last_page; i++) {
            pages.push(
                <span
                    key={i}
                    className={clsx(
                        styles['page'],
                        i === page && styles['active']
                    )}
                    onClick={() => select(i)}
                >
                    {i}
                </span>
            );
        }
    } else {
        // Always show first page
        pages.push(
            <span
                key={1}
                className={clsx(
                    styles['page'],
                    page === 1 && styles['active']
                )}
                onClick={() => select(1)}
            >
                1
            </span>
        );

        if (page > 4) {
            pages.push(
                <span key="left-ellipsis" className={styles['page']}>
                    ...
                </span>
            );
        }

        // Calculate the range of visible pages
        let startPage = Math.max(2, page - 2);
        let endPage = Math.min(last_page - 1, page + 2);

        if (page <= 4) {
            startPage = 2;
            endPage = 5;
        }

        if (page >= last_page - 3) {
            startPage = last_page - 4;
            endPage = last_page - 1;
        }

        // Render pages within the calculated range
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <span
                    key={i}
                    className={clsx(
                        styles['page'],
                        i === page && styles['active']
                    )}
                    onClick={() => select(i)}
                >
                    {i}
                </span>
            );
        }

        if (page < last_page - 3) {
            pages.push(
                <span key="right-ellipsis" className={styles['page']}>
                    ...
                </span>
            );
        }

        // Always show last page
        pages.push(
            <span
                key={last_page}
                className={clsx(
                    styles['page'],
                    page === last_page && styles['active']
                )}
                onClick={() => select(last_page)}
            >
                {last_page}
            </span>
        );
    }

    // Next Button
    pages.push(
        <span
            key="next"
            className={clsx(
                styles['next'],
                page === last_page && styles['disabled']
            )}
            onClick={() => select(page + 1)}
        >
            Next
        </span>
    );

    return pages;
}