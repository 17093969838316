import * as types from './general.types.js'
import { generalService } from '../services'
import { showErrorMessage } from '../utils'

export const getLandingDataAsync = (
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_LANDING_DATA.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await generalService.getLandingData()
      dispatch({ type: types.GET_LANDING_DATA.SUCCESS, payload: _data })
      successCallback?.()
    } catch (err) {
      dispatch({ type: types.GET_LANDING_DATA.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const companyFormSubmissionAsync = (
  payload,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.COMPANY_FORM_SUBMISSION.REQUEST })
    try {
      const {
        data: { data },
      } = await generalService.companyFormSubmission(payload)
      dispatch({ type: types.COMPANY_FORM_SUBMISSION.SUCCESS, payload: data })
      successCallback?.()
    } catch (err) {
      dispatch({ type: types.COMPANY_FORM_SUBMISSION.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}
