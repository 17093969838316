import React, { useEffect, useMemo, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import searchPageStyles from "./SearchPage.module.css";
import Select from 'react-select'
import { CompanyCard } from "../../components/CompanyCard";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCompaniesAsync } from "../../actions/company.action";
import { renderPagination } from "../../utils";

export const SearchPage = () => {
    const dispatch = useDispatch()
    const [loaded, setLoaded] = useState(false)
    const [state, setState] = useState({
        companies: [],
        per_page: 10,
        offset: 0,
        to: 0,
        last_page: 0,
        page: 1,
        current_page: 1,
        from: 0,
        total: 0,
    })
    const [searchParams] = useSearchParams();
    const query = useMemo(() => searchParams.get('query') || '', [searchParams])

    const [sortBy, setSortBy] = useState({ value: 'alphabetical', label: 'Alphabetical' });

    const handlePageChange = (page) => {
        setState((state) => ({
            ...state,
            page: page
        }))
    }

    useEffect(() => {
        setLoaded(false);
        dispatch(getCompaniesAsync({ query: query, page: 1, per_page: state.per_page, sort_by: sortBy.value }, (resp) => {
            const { data, ...pagination } = resp
            console.log(data)
            setState({
                ...pagination,
                companies: data
            });
            setLoaded(true);
        }))
    }, [query, sortBy])

    useEffect(() => {
        if (!loaded) return
        setLoaded(false);
        dispatch(getCompaniesAsync({ query: query, page: state.page, per_page: state.per_page, sort_by: sortBy.value }, (resp) => {
            const { data, ...pagination } = resp
            console.log(data)
            setState({
                ...pagination,
                companies: data
            })
            setLoaded(true);
        }))
    }, [state.page])

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [query, state, loaded])

    useEffect(() => {
        document.title = `Best Companies in Results for ${query}`;
    }, [query]);

    const options = [
        { value: 'alphabetical', label: 'Alphabetical' },
        { value: 'highest-reviews', label: 'Highest Reviews' },
        { value: 'lowest-reviews', label: 'Lowest Reviews' },
        { value: 'most-reviewed', label: 'Most Reviewed' },
        { value: 'least-reviewed', label: 'Least Reviewed' },
    ]

    return (
        <>
            <NavWithSearch />

            <section className={searchPageStyles['banner']}>
                <div className={searchPageStyles['left']}>
                    <h1>
                        Results for <br />"<span>{query}</span>"
                    </h1>
                </div>

                <div className={searchPageStyles['right']}>
                    <video muted autoPlay controls={false} src="/videos/-0c0f-4cbc-9e16-ad387c2cd394.mp4" alt="" />
                </div>
            </section>

            <section className={searchPageStyles['results']}>
                <div className={searchPageStyles['result-header']}>
                    <div className={searchPageStyles['result-summary']}>
                        <h2>Results</h2>
                        <span>{state.companies.length > 1 ? `1-${state.companies.length}` : `${state.companies.length}`} of {state.total} Results</span>
                    </div>
                    <div className={searchPageStyles['result-filter']}>
                        <span>Sort By</span>
                        <Select value={sortBy} options={options} onChange={(event) => setSortBy(event)} className="category-sort-by" />
                    </div>
                </div>

                <div className={searchPageStyles['result-list']}>

                    {
                        !loaded
                            ?
                            <div>Loading</div>
                            :
                            state.companies.length > 0
                                ?
                                state.companies.map((company, index) => (
                                    <CompanyCard company={company} />
                                ))
                                :
                                <div>No match found for this category</div>
                    }

                </div>

                {
                    state.last_page
                        ?
                        <div className={searchPageStyles['result-pagination']}>
                            {renderPagination(state.page, state.last_page, handlePageChange, searchPageStyles)}
                        </div>
                        :
                        null
                }

            </section>

            <Footer />
        </>
    );
};
