.banner {
    background: linear-gradient(90deg, #2E5676 0.09%, #4B4476 105.27%);
    padding: 28px 77px 30px 115px;

    display: flex;
    align-items: center;
}

.banner h1 {
    color: var(--Schemes-On-Primary, #FFF);
    font-family: Outfit;
    font-size: 87px;
    font-style: normal;
    font-weight: 700;
    line-height: 110px;
    text-transform: capitalize;
}

.banner h1 span {
    font-family: Outfit;
    font-size: 87px;
    font-style: normal;
    font-weight: 700;
    line-height: 110px;
    text-transform: capitalize;

    background: linear-gradient(176deg, #00CDAC 34.37%, #EDE574 61.43%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner .left {
    max-width: 600px;
    flex: 1;
}

.banner .right {
    flex: 1;
    padding: 92px 0px 92px 0px;

    display: flex;
    justify-content: center;
}

.banner .right video {
    height: 370px;

    border-radius: 30px;
    box-shadow: 0px 4px 40px 0px #FFF;
}

.results {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 140px 140px 140px;
    gap: 80px;

    flex-direction: column;
}


.result-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 22px 17px;
    background-color: rgba(45, 86, 118, 0.47);
    width: 100%;
    border-radius: 10px;
}

.result-summary {
    display: flex;
    align-items: center;
    gap: 15px;
}

.result-summary h2 {
    color: #000;
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.result-summary span {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.result-filter {
    display: flex;
    align-items: center;
    gap: 15px;
}

.result-filter span {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.result-list {
    display: flex;
    flex-direction: column;
    gap: 80px;
    max-width: 650px;
    width: 100%;
}

.result-pagination {
    display: flex;
    background-color: #FFF;
    border-radius: 15px;
    border: 1px solid #000;
}

.result-pagination span {
    color: #000;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-right: 1px solid #000;

    padding: 11px 26px;

    cursor: pointer;
}



.result-pagination span:last-child {
    border-right: none;
}

.result-pagination span.disabled {
    color: rgba(0, 0, 0, 0.29);
    pointer-events: none;
}