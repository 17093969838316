import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import forBusinessesStyles from "./ForBusinesses.module.css";
import { useDispatch } from "react-redux";
import { showToast } from "../../utils";
import { companyFormSubmissionAsync } from "../../actions/general.action";

export const ForBusinesses = () => {
    const initialState = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
    }

    const [state, setState] = useState(initialState);

    const dispatch = useDispatch();

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(companyFormSubmissionAsync(state, () => {
            setState(() => initialState)
            showToast('Message sent successfully', 'info')
        }, () => {
            // setSuccess(true)
        }))
    }

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    useEffect(() => {
        document.title = "Qolty - Connect with Us";
    }, []);

    return (
        <>
            <NavWithSearch />

            <section className={forBusinessesStyles['banner']}>
                <div className={forBusinessesStyles['left']}>
                    <h1>
                        <span>Contact us</span> with your thoughts
                    </h1>

                    <p>your feedback drives our progress.</p>
                </div>

                <div className={forBusinessesStyles['right']}>
                    <div className={forBusinessesStyles['eclipse']}></div>
                    <img src="/img/Group 15.png" alt="" />
                </div>

            </section>

            <section className={forBusinessesStyles['form']}>
                <div className={forBusinessesStyles['backdrop']}>

                    <div className={forBusinessesStyles['inner-backdrop']}>
                        <h2>Get in touch</h2>
                        <form onSubmit={onSubmit}>
                            <div className={forBusinessesStyles['inputs']}>
                                <input type="text" name="first_name" value={state.first_name} onChange={onChange} placeholder="First Name" />
                                <input type="text" name="last_name" value={state.last_name} onChange={onChange} placeholder="Last Name" />
                                <input type="text" name="email" value={state.email} onChange={onChange} placeholder="Email" />
                                <input type="text" name="phone" value={state.phone} onChange={onChange} placeholder="Phone" />
                                <textarea name="message" value={state.message} onChange={onChange} placeholder="Message"></textarea>
                            </div>
                            <button onClick={onSubmit}>Send Message</button>
                        </form>
                    </div>
                </div>

                <div className={forBusinessesStyles['support-email']}>
                    <img src="/img/mingcute-mail-fill.svg" alt="" />
                    <a href="mailto:admin@qolty.com">admin@qolty.com</a>
                </div>
            </section>


            <Footer />
        </>
    );
};
