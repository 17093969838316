import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Icon10 } from "../../icons/Icon10";
import { Icon33 } from "../../icons/Icon33";
import searchComponentStyles from "./SearchComponent.module.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const SearchComponent = ({ className }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [value, setValue] = useState('');

  const isSearchPage = useMemo(() => location.pathname === '/search', [location])

  const query = useMemo(() => searchParams.get('query') || '', [searchParams])

  useEffect(() => {
    if (isSearchPage) {
      setValue(query)
    }
  }, [isSearchPage, query])

  const handleSearch = useCallback(() => {
    if (isSearchPage) {
      searchParams.set('query', value)
      setSearchParams(searchParams)
    } else {
      navigate(`/search?query=${value}`)
    }
  }, [navigate, searchParams, setSearchParams, isSearchPage, value])

  return (
    <div className={clsx(searchComponentStyles['search-component'], className)}>
      <input onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }} value={value} placeholder="Company or Category Search" onInput={(event) => setValue(event.target.value)} />
      <button className={searchComponentStyles['hamburger-icon']}>
        <Icon10 />
      </button>
      <button className={searchComponentStyles['search-icon']} onClick={handleSearch}>
        <Icon33 />
      </button>
    </div>
  );
};
