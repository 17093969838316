.categories {
  padding: 28px 25px;
  /* border: 1px solid rgba(0, 0, 0, 0.00); */
  background: linear-gradient(270deg, #281F48 -24.8%, #2B5876 99.99%);

  --tw-shadow: 0px 16px 32px -8px rgba(12, 12, 13, .4);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  /* box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30); */

  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  /* grid-template-columns: auto auto auto; */
  gap: 34px;

}

.category {
  border-radius: 30px;
  background: rgba(217, 217, 217, 0.13);
  padding: 30px;
  display: flex;
  align-items: center;
}

.category img {
  height: 80px;
  border-radius: 100%;
  margin-right: 15px;
}

.category span {
  color: #FFF;
  font-family: Lato;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.5px;


  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}