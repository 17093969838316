import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { NavWithSearch } from "../../components/NavWithSearch";
import forgotPasswordStyles from "./ForgotPassword.module.css";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { lostPasswordAsync, verifyAsync } from "../../actions/auth.action";

export const ForgotPassword = () => {
    const initialState = { email: '' }
    const [state, setState] = useState(initialState)
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(lostPasswordAsync(state, () => {
            setSuccess(true)
            setState(initialState);
        }, () => {
            // setSuccess(true)
        }))
    }

    useEffect(() => {
        document.documentElement.classList.add('light-bg')
        return () => document.documentElement.classList.remove('light-bg')
    }, [])

    useEffect(() => {
        document.title = "Qolty - Reset Password";
    }, []);

    return (
        <>
            <NavWithSearch />

            <section className={forgotPasswordStyles['login-container']}>
                {/* <h1>Look through. Write down. Discover Organizations</h1> */}

                <div className={forgotPasswordStyles['form']}>
                    <div className={forgotPasswordStyles['left']}>
                        <img src="/img/login-banner.png" alt="" />
                    </div>
                    {
                        success
                            ?
                            <div className={forgotPasswordStyles['right']}>
                                <h3>Reset Password</h3>

                                <p className={forgotPasswordStyles['notice']}>Instructions will be sent to the email provided. Follow the instructions to reset your password</p>
                                <button className={forgotPasswordStyles['login']} onClick={() => setSuccess(false)}>
                                    Didn't Receive Email?
                                </button>
                            </div>
                            :
                            <div className={forgotPasswordStyles['right']}>
                                <h3>Reset Password</h3>

                                <p className={forgotPasswordStyles['form-help-text']}>Input your email below to receive instructions.</p>

                                <div className={forgotPasswordStyles['input-group']} style={{ marginBottom: 17 }}>
                                    <label htmlFor="">Email</label>
                                    <div className={forgotPasswordStyles['input-with-icon']}>
                                        <input
                                            type="text"
                                            name="email"
                                            value={state.email}
                                            onChange={onChange}
                                        />
                                        <div className={forgotPasswordStyles['icon']}>
                                            <img src="/img/frame.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <button className={forgotPasswordStyles['login']} onClick={onSubmit}>
                                    Send Instructions
                                </button>

                                <div className={forgotPasswordStyles['divider']}>
                                    <div className={forgotPasswordStyles['divider-line']}></div>
                                    <span className={forgotPasswordStyles['divider-text']}>OR</span>
                                    <div className={forgotPasswordStyles['divider-line']}></div>
                                </div>

                                <button className={clsx(forgotPasswordStyles['outlined-button'], forgotPasswordStyles['signup'])} onClick={() => navigate('/login')}>
                                    Back to login page
                                </button>
                            </div>
                    }
                </div>
            </section>

            <Footer />
        </>
    );
};
