.topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0px;
}

.topnav .logo img {
  height: 53px;
}

.topnav .links {
  display: flex;
  align-items: center;
}

.topnav .links a {
  font-size: 20px;
  color: #ffffff;
  text-decoration: none;
  margin-right: 40px;
  /* padding: 0px 20px; */
}

.topnav .links a:hover {
  text-decoration: underline;
}

.topnav .links a.for-business {
  font-size: 20px;

  border-radius: 10px;
  background: rgba(45, 86, 118, 0.95);
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
  border: none;

  color: #ffffff;
  text-decoration: none;
  padding: 19px 28px;

  margin-right: 0px;
}

.topnav .links a.for-business:hover {
  text-decoration: underline;
}